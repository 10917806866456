import { useEffect } from 'react';
import Breadcrumbs from '../../../components/common/Breadcrumbs';
import EyeCatch from '../../../components/common/EyeCatch';
import Body from '../../../components/layout/Body';
import useHashLink from '../../../hooks/useHashLink';

/**
 * 新規の申込方法のページ
 */
const First = () => {
    useHashLink()
    const routes = [
        { path: "/", breadcrumb: "e-Probatio トップ"},
        { path: "/query", breadcrumb: "お申し込みのご案内"},
        { path: "/query/first", breadcrumb: "新規の申込方法"},
    ];
    useEffect(() => {
        document.title = "新規の申込方法 | お申し込みのご案内 | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス";
        document.querySelector('meta[name="description"]')
            ?.setAttribute(
                "content", 
                "ＮＴＴビジネスソリューションズがお届けする、信頼の電子認証サービス e-Probatio"
            );
    }, [])
    return (
        <>
            <Body bodyClass="queryFirst">
                <EyeCatch
                    title="新規の申込方法"
                    description="初めて電子証明書（ICカード）をお申し込みされる場合のご案内となります。"
                    imgPath="/assets/images/query/h1_query_first.png"
                />
                <div className="page-content">

                    <div className="queryFirst__block queryFirst__step__intro">
                        <div className="inner">
                            <p>ICカード利用申込書の作成手順をSTEPに沿ってご案内しています。</p>
                        </div>
                    </div>

                    <div className="queryFirst__block queryFirst__step__anchor">
                        <div className="inner">
                            <div className="flexBox">
                                <div className="flexItem">
                                    <a href="#step01" className="anchorBtn">
                                        <div className="step"><span className="flex-align-center"><span>STEP<em className="num">01</em></span></span></div>
                                        <span className="ttl">必要書類のご案内</span>
                                    </a>
                                </div>
                                <div className="flexItem">
                                    <a href="#step02" className="anchorBtn">
                                        <div className="step"><span className="flex-align-center"><span>STEP<em className="num">02</em></span></span></div>
                                        <span className="ttl">必要書類の取得</span>
                                    </a>
                                </div>
                                <div className="flexItem">
                                    <a href="#step03" className="anchorBtn">
                                        <div className="step"><span className="flex-align-center"><span>STEP<em className="num">03</em></span></span></div>
                                        <span className="ttl">必要書類の送付<br />／審査と発行</span>
                                    </a>
                                </div>
                                <div className="flexItem">
                                    <a href="#step04" className="anchorBtn">
                                        <div className="step"><span className="flex-align-center"><span>STEP<em className="num">04</em></span></span></div>
                                        <span className="ttl">ICカードの受取</span>
                                    </a>
                                </div>
                                <div className="flexItem">
                                    <a href="#step05" className="anchorBtn">
                                        <div className="step"><span className="flex-align-center"><span>STEP<em className="num">05</em></span></span></div>
                                        <span className="ttl">内容確認<br className="pc" />／受領書の返送</span>
                                    </a>
                                </div>
                                <div className="flexItem">
                                    <a href="#step06" className="anchorBtn">
                                        <div className="step"><span className="flex-align-center"><span>STEP<em className="num">06</em></span></span></div>
                                        <span className="ttl">お支払い</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="step01" className="queryFirst__block queryFirst__step__block step01">
                        <div className="inner">
                            <div className="queryFirst__step__head">
                                <div className="step__title">
                                    <div className="step__title__num"><span className="flex-align-center"><span>STEP<em className="num">01</em></span></span></div>
                                    <div className="step__title__txt">
                                        <h2 className="ttl">必要書類のご案内</h2>
                                    </div>
                                </div>
                                <p className="step__leadTxt">名義人によって必要書類が異なります。該当するタブをクリックして公的書類をご参照ください。<br />
                                    ※ICカードのお申し込みをいただきますとキャンセルはいたしかねます。</p>
                            </div>
                            <div className="queryFirst__step__body">
                                <div className="inner">

                                    <div className="queryFirst__select__buttons">
                                        <div className="flexBox">
                                            <div className="flexItem"><button className="selectBtn selectBtn_1 on" data-select="s1-1">名義人が会社<br className="sp" />代表者<br className="pc" />の場合</button></div>
                                            <div className="flexItem"><button className="selectBtn selectBtn_1" data-select="s1-2">名義人が会社<br className="sp" />代表者<br className="pc" />以外の場合</button></div>
                                            <div className="flexItem"><button className="selectBtn selectBtn_1" data-select="s1-3">名義人が商業登記<br />または商号登記を<br className="sp" />されて<br className="pc" />いない個人<br className="sp" />事業主の場合</button></div>
                                            <div className="flexItem"><button className="selectBtn selectBtn_1" data-select="s1-4">名義人が省官庁／<br />地方公共団体に<br />所属する方の場合</button></div>
                                        </div>
                                    </div>

                                    <div className="queryFirst__select__contents">

                                        <div id="s1-1" className="queryFirst__select__contents__box selectBox_1 open">

                                            <h3 className="box__title">名義人が会社代表者の場合</h3>
                                            <p className="step__leadTxt">
                                                     ※
                                                     <a href="https://www.moj.go.jp/MINJI/minji06_00210.html" target="_blank" className="blank" rel="noreferrer" >代表取締役等住所非表示措置</a>
                                                     が講じられた会社代表者からの申込の場合は、『名義人が会社代表者以外の場合』タブの書類が必要です。
                                            </p>

                                            <table className="queryFirst__tbl">
                                                <tbody>
                                                    <tr>
                                                        <th><span className="c-0072bc">ICカード利用<br className="sp" />申込書</span></th>
                                                        <td>
                                                            <ul>
                                                                <li>名義人個人の実印があるもの</li>
                                                                <li>弊社書式</li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            <p>下記のいずれか1部</p>
                                                            <ul>
                                                                <li><span className="c-0072bc">住民票の写し</span></li>
                                                                <li><span className="c-0072bc">住民票記載事項証明書</span></li>
                                                            </ul>
                                                        </th>
                                                        <td>
                                                            <ul>
                                                                <li>名義人個人の原本</li>
                                                                <li>発行日より3ヶ月以内のもの</li>
                                                                <li><span className="c-e72929">マイナンバーが記載されていないもの</span></li>
                                                                <li>氏名、現住所、生年月日、発行日が記載されているもの</li>
                                                                <li><span className="c-e72929">コピーではなく市役所などが発行した原本</span></li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th><span className="c-0072bc">印鑑登録証明書</span></th>
                                                        <td>
                                                            <ul>
                                                                <li>名義人個人の原本</li>
                                                                <li>発行日より3ヶ月以内のもの</li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th><span className="c-0072bc">登記事項証明書(商業・法人登記)</span><br />
                                                            履歴事項全部証明書<br className="pc" />
                                                            または<br />
                                                            現在事項全部証明書</th>
                                                        <td>
                                                            <ul>
                                                                <li>原本</li>
                                                                <li>発行日より6ヶ月以内のもの</li>
                                                                <li>法務局発行のもの</li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th><span className="c-0072bc">ICカードリーダー購入申込書</span></th>
                                                        <td>
                                                            <ul>
                                                                <li>ICカードリーダーをご購入される場合</li>
                                                                <li>弊社書式</li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>

                                        <div id="s1-2" className="queryFirst__select__contents__box selectBox_1 ">

                                            <h3 className="box__title">名義人が会社代表者以外の場合<br />（<a href="https://www.moj.go.jp/MINJI/minji06_00210.html" target="_blank" className="blank" rel="noreferrer" >代表取締役等住所非表示措置</a>
                                            が講じられた会社代表者を含む）</h3>

                                            <table className="queryFirst__tbl">
                                                <tbody>
                                                    <tr>
                                                        <th><span className="c-0072bc">ICカード利用申込書</span></th>
                                                        <td>
                                                            <ul>
                                                                <li>名義人個人の実印があるもの</li>
                                                                <li>弊社書式</li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            <p>下記のいずれか1部</p>
                                                            <ul>
                                                                <li><span className="c-0072bc">住民票の写し</span></li>
                                                                <li><span className="c-0072bc">住民票記載事項証明書</span></li>
                                                            </ul>
                                                        </th>
                                                        <td>
                                                            <ul>
                                                                <li>名義人個人の原本</li>
                                                                <li>発行日より3ヶ月以内のもの</li>
                                                                <li><span className="c-e72929">マイナンバーが記載されていないもの</span></li>
                                                                <li>氏名、現住所、生年月日、発行日が記載されているもの</li>
                                                                <li><span className="c-e72929">コピーではなく市役所などが発行した原本</span></li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th><span className="c-0072bc">印鑑登録証明書</span></th>
                                                        <td>
                                                            <ul>
                                                                <li>名義人個人の原本</li>
                                                                <li>発行日より3ヶ月以内のもの</li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th><span className="c-0072bc">登記事項証明書(商業・法人登記)</span><br />
                                                            履歴事項全部証明書<br />
                                                            または<br />
                                                            現在事項全部証明書</th>
                                                        <td>
                                                            <ul>
                                                                <li>原本</li>
                                                                <li>発行日より6ヶ月以内のもの</li>
                                                                <li>法務局発行のもの</li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            <p>下記のいずれか1部</p>
                                                            <ul>
                                                                <li><a href='/download/ps2-register.pdf' target="_blank" rel="noreferrer" className="pdf blank"><span className="c-0072bc">企業在籍証明書（PDF）</span></a></li>
                                                                <li><span className="c-0072bc">企業在籍兼電子委任項目証明書</span></li>
                                                            </ul>
                                                        </th>
                                                        <td>
                                                            <ul>
                                                                <li>法人の実印があるもの</li>
                                                                <li>弊社書式</li>
                                                                <li>ICカードに委任項目の登録を希望される場合は「企業在籍兼電子委任項目証明書」をご利用ください。<br />
                                                                    作成は<a href="/web-prob/" target="_blank" className="blank" rel="noreferrer">こちら</a>から。<br />
                                                                    詳しくは<a href="/faq/#faq1_2988" target="_blank" className="blank">こちら</a>をご参照ください。</li>
                                                            </ul>
                                                            <p className="attent"><small className="c-e72929">企業在籍兼電子委任項目証明書に印字されるバーコード番号のご使用は一度限りです。ICカード利用申込書ごとに企業在籍兼電子委任項目証明書の作成をお願いいたします。</small></p>
                                                            <p className="attent"><small className="c-e72929">代表取締役等住所非表示措置が講じられた会社代表者からの申込時は「企業在籍証明書」を作成ください。</small></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th><span className="c-0072bc">印鑑証明書</span></th>
                                                        <td>
                                                            <ul>
                                                                <li>法人の実印の原本</li>
                                                                <li>発行日より6ヶ月以内のもの</li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th><span className="c-0072bc">ICカードリーダー購入申込書</span></th>
                                                        <td>
                                                            <ul>
                                                                <li>ICカードリーダーをご購入される場合</li>
                                                                <li>弊社書式</li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>

                                        <div id="s1-3" className="queryFirst__select__contents__box selectBox_1 ">

                                            <h3 className="box__title">名義人が商業登記または商号登記を<br className="sp" />されていない個人事業主の場合</h3>

                                            <table className="queryFirst__tbl">
                                                <tbody>
                                                    <tr>
                                                        <th><span className="c-0072bc">ICカード利用申込書</span></th>
                                                        <td>
                                                            <ul>
                                                                <li>名義人個人の実印があるもの</li>
                                                                <li>弊社書式</li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            <p>下記のいずれか1部</p>
                                                            <ul>
                                                                <li><span className="c-0072bc">住民票の写し</span></li>
                                                                <li><span className="c-0072bc">住民票記載事項証明書</span></li>
                                                            </ul>
                                                        </th>
                                                        <td>
                                                            <ul>
                                                                <li>名義人個人の原本</li>
                                                                <li>発行日より3ヶ月以内のもの</li>
                                                                <li><span className="c-e72929">マイナンバーが記載されていないもの</span></li>
                                                                <li>氏名、現住所、生年月日、発行日が記載されているもの</li>
                                                                <li><span className="c-e72929">コピーではなく市役所などが発行した原本</span></li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th><span className="c-0072bc">印鑑登録証明書</span></th>
                                                        <td>
                                                            <ul>
                                                                <li>名義人個人の原本</li>
                                                                <li>発行日より3ヶ月以内のもの</li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th><p>下記のいずれか1部のコピー</p>
                                                            <ul>
                                                                <li><span className="c-0072bc">青色・白色申告</span></li>
                                                                <li><span className="c-0072bc">官公庁が発行した許認可証</span></li>
                                                                <li><span className="c-0072bc">納税証明書（その3の2）</span></li>
                                                            </ul>
                                                        </th>
                                                        <td>
                                                            <ul>
                                                                <li>収受日付印が<br className="sp" />押印されているもの</li>
                                                            </ul>
                                                            <p className="attent"><small>電子申告の場合は<br className="pc" />
                                                                「受信通知（メール詳細）」および「申告データ出力分」<br className="pc" />
                                                                確認方法は<a href="/faq/#faq1_2864" target="_blank" className="blank">こちら</a>をご参照ください。</small></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th><span className="c-0072bc">ICカードリーダー購入申込書</span></th>
                                                        <td>
                                                            <ul>
                                                                <li>ICカードリーダーをご購入される場合</li>
                                                                <li>弊社書式</li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>

                                        <div id="s1-4" className="queryFirst__select__contents__box selectBox_1 ">

                                            <h3 className="box__title">名義人が省官庁／<br className="sp" />地方公共団体に所属する方の場合</h3>

                                            <table className="queryFirst__tbl">
                                                <tbody>
                                                    <tr>
                                                        <th><span className="c-0072bc">ICカード利用申込書</span></th>
                                                        <td>
                                                            <ul>
                                                                <li>名義人個人の実印があるもの</li>
                                                                <li>弊社書式</li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            <p>下記のいずれか1部</p>
                                                            <ul>
                                                                <li><span className="c-0072bc">住民票の写し</span></li>
                                                                <li><span className="c-0072bc">住民票記載事項証明書</span></li>
                                                            </ul>
                                                        </th>
                                                        <td>
                                                            <ul>
                                                                <li>名義人個人の原本</li>
                                                                <li>発行日より3ヶ月以内のもの</li>
                                                                <li><span className="c-e72929">マイナンバーが記載されていないもの</span></li>
                                                                <li>氏名、現住所、生年月日、発行日が記載されているもの</li>
                                                                <li><span className="c-e72929">コピーではなく市役所などが発行した原本</span></li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th><span className="c-0072bc">印鑑登録証明書</span></th>
                                                        <td>
                                                            <ul>
                                                                <li>名義人個人の原本</li>
                                                                <li>発行日より3ヶ月以内のもの</li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            <p>下記のいずれか1部</p>
                                                            <ul>
                                                                <li><span className="c-0072bc">法人番号指定<br className="sp" />通知書のコピー</span></li>
                                                                <li><span className="c-0072bc">法人情報の画面<br className="sp" />を印刷したもの</span></li>
                                                            </ul>
                                                            <p><a href="https://www.houjin-bangou.nta.go.jp/shitsumon/shosai.html?selQaId=00059" target="_blank" className="blank" rel="noreferrer">印刷方法に<br className="sp" />ついて</a></p>
                                                            <p><a href="https://www.houjin-bangou.nta.go.jp/" target="_blank" className="blank" rel="noreferrer">法人番号検索<br className="sp" />サイトは<br className="sp" />こちら</a></p>
                                                        </th>
                                                        <td>
                                                            <ul>
                                                                <li><a href="https://www.houjin-bangou.nta.go.jp/setsumei/tsuchisho/" target="_blank" className="blank" rel="noreferrer">国税庁発行のもの</a></li>
                                                            </ul>
                                                            <p className="attent"><small className="c-e72929">提出がなく法人番号が確認できない場合は、ICカード内に<br />
                                                                法人番号は登録されません。</small></p>
                                                            <p className="attent"><small className="c-e72929">ICカードに委任項目の登録を希望される場合<br />
                                                                は必須となります。</small></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            <p>下記のいずれか1部</p>
                                                            <ul>
                                                                <li><a href="/download/ps2-publicregister.pdf" target="_blank" className="pdf blank" rel="noreferrer">省官庁／地方公共団体在籍証明書（PDF）</a></li>
                                                                <li><span className="c-0072bc">省官庁／地方公共団体在籍兼電子委任項目証明書</span></li>
                                                            </ul>
                                                        </th>
                                                        <td>
                                                            <ul>
                                                                <li>弊社書式</li>
                                                                <li>ICカードに電子委任項目の登録を希望される場合は「省官庁/地方公共団体在籍兼電子委任項目証明書」をご利用ください。作成は<a href="/web-prob/" target="_blank" className="blank" rel="noreferrer">こちら</a>から。<br />
                                                                    詳しくは<a href="/faq/#faq1_2988" target="_blank" className="blank">こちら</a>をご参照ください。</li>
                                                            </ul>
                                                            <p className="attent"><small className="c-e72929">省官庁/地方公共団体在籍兼電子委任項目証明書を再利用することはできません。（省官庁/地方公共団体在籍兼電子委任項目証明書に印字されるバーコード番号のご使用は1度限りです。）<br />
                                                                省官庁/地方公共団体在籍兼電子委任項目証明書の内容が同じ申込書を複数、または再度申し込みされる場合でも、ICカード利用申込書ごとに、省官庁/地方公共団体在籍兼電子委任項目証明書の作成をお願いいたします。</small></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th><span className="c-0072bc">ICカードリーダー購入申込書</span></th>
                                                        <td>
                                                            <ul>
                                                                <li>ICカードリーダーをご購入される場合</li>
                                                                <li>弊社書式</li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>

                                        <div className="queryFirst__attention">
                                            <h3>ご注意</h3>
                                            <ul>
                                            <li>電子入札を行う際、名義人決定について各発注機関、ご利用目的により制限がかかる場合があります。<br className="pc" />必ず各発注機関にお問い合わせください。<br />
                                                尚、参加資格申請されている方がご不明の場合は、お客さまの各発注機関にお問い合わせください。</li>
                                            <li>書類には名義人個人の実印、公的書類が必要になります。</li>
                                            <li>名義人個人の実印の押印は、ICカード受領後の「受領書」返送時にも必要になります。</li>
                                            </ul>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            
                            <a href="#step02" className="queryFirst__nextstep e_btn_anchor sp">次へ</a>
                        </div>
                    </div>

                    <div id="step02" className="queryFirst__block queryFirst__step__block step02">
                        <div className="inner">
                            <div className="queryFirst__step__head">
                                <div className="step__title">
                                    <div className="step__title__num"><span className="flex-align-center"><span>STEP<em className="num">02</em></span></span></div>
                                    <div className="step__title__txt">
                                        <h2 className="ttl">必要書類の取得</h2>
                                    </div>
                                </div>
                                <p className="step__leadTxt">利用申込書を取得する方法のご案内です。利用申込書の取得には3種類の方法をご用意しておりますが、<br />
                                    弊社では簡単でお得な申込書作成ツール「ぷろばてぃお君」をおススメいたします。</p>
                            </div>
                            <div className="queryFirst__step__body">
                                <div className="inner">

                                    <div className="queryFirst__select__buttons">
                                        <div className="flexBox">
                                            <div className="flexItem"><button className="selectBtn selectBtn_2 on" data-select="s2-1">パソコンで利用申込書を作成する<br />（ぷろばてぃお君のご利用）</button></div>
                                            <div className="flexItem"><button className="selectBtn selectBtn_2" data-select="s2-2">印刷して手書きで<br className="pc" />利用申込書を作成する</button></div>
                                            <div className="flexItem"><button className="selectBtn selectBtn_2" data-select="s2-3">郵送にて利用申込書を<br className="pc" />取り寄せ作成する</button></div>
                                        </div>
                                    </div>

                                    <div className="queryFirst__select__contents">

                                        <div id="s2-1" className="queryFirst__select__contents__box selectBox_2 open">

                                            <h3 className="box__title">パソコンで利用申込書を作成する<br className="sp" />（ぷろばてぃお君のご利用）</h3>
                                            
                                            <p className="toolBtn"><a href="/web-prob/" target="_blank" className="blank" rel="noreferrer">申し込み書作成ツール「ぷろぱてぃお君」</a></p>

                                            <p className="fs-13 text-center c-e72929">※申込方法に関して、ご不明な点は<br className="sp" /><a href="../../faq" style={{color: "#E72929"}}>よくあるご質問</a>でご参照ください。</p>

                                            <div className="queryFirst__attention">
                                                <h3>ご注意</h3>
                                                <ul>
                                                    <li>電子入札を行う際、名義人決定について各発注機関、ご利用目的により制限がかかる場合があります。<br />
                                                        必ず各発注機関にお問い合わせください。<br />
                                                        尚、参加資格申請されている方がご不明の場合は、お客さまの各発注機関にお問い合わせください。</li>
                                                    <li>書類には名義人個人の実印、書類が必要になります。 ICカードの発行が遅れる場合がございます。</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div id="s2-2" className="queryFirst__select__contents__box selectBox_2 ">

                                            <h3 className="box__title">印刷して手書きで利用申込書を作成する</h3>

                                            <h4>ICカード利用申込書</h4>

                                            <p>ICカード利用申込書をダウンロードするには、下記の5つの文書に同意していただく必要があります。<br />
                                                5つの文書に同意していただける場合、下記のボタンをクリックしてICカード利用申込書を印刷後、ご記入の上お申し込みください。</p>
                                        
                                            <ol>
                                                <li><a href="/download/ps2-import.pdf" className="pdf">e-Probatio PS2 サービス重要事項説明書（PDF）</a></li>
                                                <li><a href="/download/personal.pdf" className="pdf">e-Probatio認証局 個人情報取扱要領（PDF）</a></li>
                                                <li><a href="/download/ps2-kiyaku.pdf" className="pdf">e-Probatio PS2 サービス利用約款（PDF）</a></li>
                                                <li><a href="/download/cps.pdf" className="pdf">e-Probatio認証局 認証業務規程（CPS）（PDF）</a></li>
                                                <li><a href="/download/ps2-cp.pdf" className="pdf">e-Probatio PS2 サービス証明書ポリシー（CP）（PDF）</a></li>
                                            </ol>

                                            <div>
                                                <p className="link_2_1"><a href="/download/ps2-appli.pdf" target="_blank" className="e_btn type_blank" rel="noreferrer">1~5の文書に一括同意し、<br className="sp" />「e-Probatio PS2サービス<br className="pc" />ICカード<br className="sp" />利用申込書」をダウンロードする</a></p>
                                                <p className="attent"><small className="c-e72929">※ICカード発行時のローマ字（名前、住所）記載はヘボン式での記入方法をご参照ください。<br />
                                                    ※申込方法に関して、ご不明な点は<a href="../../faq" style={{color: "#E72929"}}>よくあるご質問</a>でご参照ください。</small></p>
                                            </div>

                                            <h4>ICカードリーダー購入申込書</h4>

                                            <p>ICカードをご利用いただくにはICカードリーダーが必要です。<br />
                                                下記のボタンをクリックしてICカードリーダー購入申込書を印刷後、ご記入の上お申し込みください。</p>

                                            <div>
                                                <p className="link_2_2"><a href="/download/ps2-iccard.pdf" target="_blank" className="e_btn type_blank" rel="noreferrer">ICカードリーダー購入申込書<br className="sp" />（ps2-iccard.pdf）</a></p>
                                            </div>

                                            <div className="queryFirst__attention">
                                                <h3>ご注意</h3>
                                                <ul>
                                                    <li>電子入札を行う際、名義人決定について各発注機関、ご利用目的により制限がかかる場合があります。<br />
                                                        必ず各発注機関にお問い合わせください。<br />
                                                        尚、参加資格申請されている方がご不明の場合は、お客さまの各発注機関にお問い合わせください。</li>
                                                    <li>書類には名義人個人の実印、書類が必要になります。</li>
                                                </ul>
                                            </div>
    
                                        </div>

                                        <div id="s2-3" className="queryFirst__select__contents__box selectBox_2 ">

                                            <h3 className="box__title">郵送にて利用申込書を取り寄せ作成する</h3>

                                            <p>お電話が繋がりにくい場合がございます。<br />
                                                お問い合わせページからのご依頼をおススメいたします。</p>
                                                <p className="attent"><small className="c-e72929">※申込方法に関して、ご不明な点は<a href="../../faq" style={{color: "#E72929"}}>よくあるご質問</a>でご参照ください。</small></p>

                                            <div>
                                                <p className="contactBtn"><a href="/contact/">お問い合わせ</a></p>
                                            </div>

                                            <div className="queryFirst__attention">
                                                <h3>ご注意</h3>
                                                <ul>
                                                    <li>電子入札を行う際、名義人決定について各発注機関、ご利用目的により制限がかかる場合があります。<br className="pc" />必ず各発注機関にお問い合わせください。<br />
                                                        尚、参加資格申請されている方がご不明の場合は、お客さまの各発注機関にお問い合わせください。</li>
                                                    <li>書類には名義人個人の実印、書類が必要になります。</li>
                                                </ul>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>

                            <a href="#step03" className="queryFirst__nextstep e_btn_anchor sp">次へ</a>
                        </div>
                    </div>

                    <div id="step03" className="queryFirst__block queryFirst__step__block step03">
                        <div className="inner">
                            <div className="queryFirst__step__head">
                                <div className="step__title">
                                    <div className="step__title__num"><span className="flex-align-center"><span>STEP<em className="num">03</em></span></span></div>
                                    <div className="step__title__txt">
                                        <h2 className="ttl">必要書類の送付<br className="sp" />／審査と発行</h2>
                                    </div>
                                </div>
                                <p className="step__leadTxt">ICカード利用申込書に必要事項をご記入いただき、ご郵送ください。<br />
                                    弊社に到着後、書類に誤りがないか審査し、ICカードを発送いたします。郵送事故防止のためにも簡易書留制度のご利用をおススメいたします。</p>
                            </div>
                            <div className="queryFirst__step__body">
                                <div className="inner">

                                    <div className="queryFirst__contents__box">
                                        <h3 className="box__title">申込書送付先</h3>
                                        <p>〒530-0003<br />
                                            大阪市北区堂島3-1-21 ＮＴＴデータ堂島ビル16階<br />
                                            ＮＴＴビジネスソリューションズ株式会社<br />
                                            バリューデザイン部　<br className="sp" />
                                            トラストビジネス担当<br />
                                            &nbsp;<br />
                                            お送りいただきました書類を元にICカードを発行いたします。<br />
                                            審査～発送まで約<span className="c-e72929">2週間</span>お時間をいただきます。 </p>
                                        <figure className="step_3_1">
                                            <picture>
                                                <source media="(max-width: 767px)" srcSet="/assets/images/query/first/step_3_1_sp.png" />
                                                <img src="/assets/images/query/first/step_3_1.png" alt="画像" />
                                            </picture>
                                        </figure>
                                    </div>

                                    <div className="queryFirst__attention">
                                        <h3>ご注意</h3>
                                        <ul>
                                            <li>取得された公的書類の内容から誤りないようご記入ください。</li>
                                            <li>郵送いただきました申込書類に修正が生じた場合は、申込書類を返送した上で、お客さまに訂正していただくため、 ICカードの発行が遅れる場合がございます。</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>

                            <a href="#step04" className="queryFirst__nextstep e_btn_anchor sp">次へ</a>
                        </div>
                    </div>

                    <div id="step04" className="queryFirst__block queryFirst__step__block step04">
                        <div className="inner">
                            <div className="queryFirst__step__head">
                                <div className="step__title">
                                    <div className="step__title__num"><span className="flex-align-center"><span>STEP<em className="num">04</em></span></span></div>
                                    <div className="step__title__txt">
                                        <h2 className="ttl">ICカードの受取</h2>
                                    </div>
                                </div>
                                <p className="step__leadTxt">ICカードのお受取りには本人限定郵便を利用いたします。<br />
                                    2種類の受取方法がございますのでお客さまに合ったものを下記よりお選びください。<br />
                                    本人限定受取郵便の詳細については「<a href="https://www.post.japanpost.jp/index.html" target="_blank" className="blank" rel="noreferrer">日本郵便のサイト</a>」でご参照ください。</p>
                            </div>
                            <div className="queryFirst__step__body">
                                <div className="inner">

                                    <div className="queryFirst__select__buttons">
                                        <div className="flexBox">
                                            <div className="flexItem"><button className="selectBtn selectBtn_4 on" data-select="s4-1">名義人がお受取りの場合<br className="pc" />(基本型)</button></div>
                                            <div className="flexItem"><button className="selectBtn selectBtn_4" data-select="s4-2">受取代人を指定された場合<br className="pc" />(特例型)</button></div>
                                        </div>
                                    </div>

                                    <div className="queryFirst__select__contents">

                                        <div id="s4-1" className="queryFirst__select__contents__box selectBox_4 open">

                                            <div className="queryFirst__contents__box">
                                                <h3 className="box__title">名義人がお受取りの場合(基本型)</h3>
                                                <figure className="step_4_1"><img src="/assets/images/query/first/step_4_1.png" alt="画像" /></figure>
                                                <ol>
                                                    <li>ICカードと暗証番号（PIN）を『本人限定受取郵便（基本型）』にて、 名義人住民票ご住所の管轄の取扱郵便局へお届けします。</li>
                                                    <li>管轄の郵便局から名義人の住民票ご住所に郵便物到着のお知らせが届きます。</li>
                                                    <li>名義人のみ管轄の取扱郵便局にて、ICカードをお受取りいただけます。</li>
                                                    <li>ICカードリーダーは宅配便にて指定住所にお送りいたします。</li>
                                                </ol>
                                            </div>

                                            <div className="queryFirst__attention">
                                                <h3>ご注意</h3>
                                                <ul>
                                                    <li>ICカードはご自宅には届きません。</li>
                                                    <li>お申し込まれた後で受取代人を指名する事はできません。</li>
                                                    <li><a href="/faq/#faq1_2914">住民票に、集合住宅（マンション等）の部屋番号の記載が無い場合</a></li>
                                                </ul>
                                            </div>

                                            <div className="queryFirst__send">
                                                <h3>送付内容</h3>
                                                <ul>
                                                    <li>ICカード</li>
                                                    <li>受領書</li>
                                                    <li>受領書返送用封筒</li>
                                                    <li>暗証番号（PIN）</li>
                                                </ul>
                                            </div>

                                        </div>

                                        <div id="s4-2" className="queryFirst__select__contents__box selectBox_4">

                                            <div className="queryFirst__contents__box">
                                                <h3 className="box__title">受取代人を指定された場合(特例型)</h3>
                                                <figure className="step_4_2"><img src="/assets/images/query/first/step_4_2.png" alt="画像" /></figure>
                                                <ol>
                                                    <li>ICカードを『本人限定受取郵便（特例型）』にて名義人住民票ご住所の管轄の取扱郵便局へお届けします。</li>
                                                    <li>管轄の取扱郵便局から名義人住民票ご住所に郵便物到着のお知らせが届きます。</li>
                                                    <li>受取代人は、名義人住民票ご住所の管轄の取扱郵便局にて、ICカードをお受取りください。</li>
                                                    <li>暗証番号（PIN）は『簡易書留郵便』で名義人住民票ご住所へお届けします。</li>
                                                    <li>ICカードリーダーは宅配便にて指定住所にお送りいたします。</li>
                                                </ol>
                                            </div>

                                            <div className="queryFirst__attention">
                                                <h3>ご注意</h3>
                                                <ul>
                                                    <li>あらかじめお申し込み時に申込書に記入していただく必要があります。</li>
                                                    <li>ICカードと暗証番号（PIN）は別送です。</li>
                                                    <li>受取代人のご住所に郵便到着のお知らせは届きません。</li>
                                                    <li><a href="/faq/#faq1_2914">住民票に、集合住宅（マンション等）の部屋番号の記載が無い場合</a></li>
                                                </ul>
                                            </div>

                                            <div className="queryFirst__send">
                                                <h3>送付内容</h3>
                                                <ul>
                                                    <li>ICカード</li>
                                                    <li>受領書</li>
                                                    <li>受領書返送用封筒</li>
                                                    <li>暗証番号（PIN）</li>
                                                </ul>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>

                            <a href="#step05" className="queryFirst__nextstep e_btn_anchor sp">次へ</a>
                        </div>
                    </div>

                    <div id="step05" className="queryFirst__block queryFirst__step__block step05">
                        <div className="inner">
                            <div className="queryFirst__step__head">
                                <div className="step__title">
                                    <div className="step__title__num"><span className="flex-align-center"><span>STEP<em className="num">05</em></span></span></div>
                                    <div className="step__title__txt">
                                        <h2 className="ttl">内容確認／受領書の返送</h2>
                                    </div>
                                </div>
                                <p className="step__leadTxt">ICカードをお受取り後は、送付または印刷されたマニュアルに沿って内容確認、利用者登録へお進みください。</p>
                            </div>
                            <div className="queryFirst__step__body">
                                <div className="inner">

                                    <div className="queryFirst__contents__box">
                                        <h3 className="box__title">セットアップとICカードの内容確認</h3>
                                        <ol>
                                            <li>まず、「管理ツールダウンロードのご案内」をご参照ください。</li>
                                            <li>電子システムを利用する際に必要なソフトは、<a href="../support/tool" className="blank" target="_blank" rel="noreferrer">こちら</a>よりインストールできます。</li>
                                            <li>「ユーザー名・パスワード」を入力してください。</li>
                                            <li>管理ツールの詳細は<a href="https://support.e-probatio.com/man/setup-2.pdf" target="_blank" className="blank" rel="noreferrer">マニュアル</a>をご参照ください。<br />有効期間前でも行えます。</li>
                                        </ol>
                                    </div>

                                    <div className="queryFirst__contents__box">
                                        <h3 className="box__title">受領書の返送</h3>
                                        <ul>
                                            <li>ICカードの内容をご確認後は、受領書にICカードご名義人の実印（お申込書に押印いただいた印鑑証明書と同じご印鑑）を押印後、早急にご返送ください。</li>
                                            <li>受領書に記載されている返送期日までにご返送の無い場合は、ICカードの失効処理を行う場合がございますので、ご注意ください。</li>
                                            <li>失効した場合でもICカード発行料金のキャンセル・ご返金はいたしませんので、あらかじめご了承ください。（失効後に再発行手続を行う場合は、新規発行と同様の手続きとなります。）</li>
                                        </ul>
                                        <p className="link link_faq"><a href="/faq/#faq1_2914">受領書のFAQへ</a></p>
                                    </div>

                                    <div className="queryFirst__contents__box">
                                        <h3 className="box__title">利用者登録</h3>
                                        <p>利用するシステムへ登録を行います。</p>
                                        <ul>
                                            <li>利用者登録はカードに記載されている有効期間内に行ってください。</li>
                                            <li>詳しい利用者登録方法については各発注機関にお問い合わせください。</li>
                                        </ul>
                                    </div>

                                    <div className="queryFirst__attention">
                                        <h3>ご注意</h3>
                                        <ul>
                                            <li>利用者登録はカードに記載されている有効期間内に行ってください。</li>
                                            <li>ICカードの内容をご確認後は、受領書にICカードご名義人の実印（お申込書に押印いただいた印鑑証明書と同じご印鑑）を押印し、早急にご返送ください。</li>
                                            <li>期限までにご返送のない場合は、ICカードの失効処理を行う場合がありますので、ご注意ください。</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>

                            <a href="#step06" className="queryFirst__nextstep e_btn_anchor sp">次へ</a>
                        </div>
                    </div>

                    <div id="step06" className="queryFirst__block queryFirst__step__block step06">
                        <div className="inner">
                            <div className="queryFirst__step__head">
                                <div className="step__title">
                                    <div className="step__title__num"><span className="flex-align-center"><span>STEP<em className="num">06</em></span></span></div>
                                    <div className="step__title__txt">
                                        <h2 className="ttl">お支払い</h2>
                                    </div>
                                </div>
                                <p className="step__leadTxt">ICカードの発送後、 数日以内に別途請求書を送付いたします。<br />
                                    請求書をお受取り後、振込期日内に請求書に記載された指定口座へ、お振込みください。<br />
                                    &nbsp;<br />
                                    なお、セットアップサービス（有償）をお申し込みのお客さまは、セットアップサービス完了後に、請求書を送付いたします。<br />
                                    &nbsp;<br />
                                    お支払い方法は請求書による振込支払いのみです。<br />
                                    振込手数料はお客さまのご負担とさせていただきますので、ご了承ください。<br />
                                    &nbsp;<br />
                                    領収書は発行しておりません。<br />
                                    お振込みにてお支払いの際に発行される振込明細書等をご利用ください。</p>
                            </div>
                            <div className="queryFirst__step__body">
                                <div className="inner">

                                    <div className="queryFirst__attention">
                                        <h3>ご注意</h3>
                                        <ul>
                                            <li>お支払い方法は請求書による振込支払いのみです。</li>
                                        </ul>
                                    </div>

                                    <div className="queryFirst__finish__message">
                                        <p>以上で手続きは終了です。</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="queryFirst__pageLink">
                        <h2>お申し込みにあたってはこちらをご利用ください</h2>
                        <div className="cont">
                            <a href="/web-prob/" target="_blank" rel="noreferrer">
                                <figure className="img">
                                    <picture>
                                        <source media="(max-width: 767px)" srcSet="/assets/images/query/first/pageLink_sp.png" />
                                        <img src="/assets/images/query/first/pageLink_pc.png" alt="" />
                                    </picture>
                                </figure>
                                <div className="textarea">
                                    <p className="t1">申込書作成ツール</p>
                                    <h3 className="ttl">「ぷろばてぃお君」</h3>
                                    <p className="t2">パソコンから簡単に利用申込書を作成いただけます。</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <Breadcrumbs routes={routes} />
            </Body>
        </>
    );
}

export default First;